import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { ToastContainer } from 'react-toastify';
import ReactGA from "react-ga4";

ReactGA.initialize("G-BZCGRYDG2G");
ReactGA.send({ hitType: "pageview", page: window.location.pathname })

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<React.StrictMode>
		<ToastContainer
			position="top-center"
			autoClose={2500}
			hideProgressBar={false}
			newestOnTop={false}
			closeOnClick
			rtl={false}
			pauseOnFocusLoss
			draggable
			theme="colored" />
		<App />
	</React.StrictMode>
);
