import React from 'react';
import './estadisticas.css'
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts'

import ReactGA from "react-ga4";

class Estadisticas extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            commandsData: [],
            addedCategory: [],
            loading: false
        }
    }

    setState(state) {
        state.addedCategory = []
        super.setState(state)
    }

    componentDidMount() {
        this.state.loading = true // eslint-disable-line react/no-direct-mutation-state
    }

    getAmmountAndCommandPerDayPerCategory(commands) {
        let commandAndAmmountPerDayPerCategory = {}
        commands.forEach((command) => {
            let date = new Date(command.hora)
            let day = date.getDate()
            let month = date.getMonth() + 1
            let year = date.getFullYear()
            let category = command.categoria
            let dateStr = `${day}-${month}-${year}`
            if (!(category in commandAndAmmountPerDayPerCategory)) {
                commandAndAmmountPerDayPerCategory[category] = {}
            }

            if (commandAndAmmountPerDayPerCategory[category][dateStr] && command.nombre in commandAndAmmountPerDayPerCategory[category][dateStr]) {
                commandAndAmmountPerDayPerCategory[category][dateStr][command.nombre]["ammount"] += 1
            } else {
                commandAndAmmountPerDayPerCategory[category][dateStr] = { ...commandAndAmmountPerDayPerCategory[category][dateStr], [command.nombre]: { "ammount": 1 } }
            }
        })

        return commandAndAmmountPerDayPerCategory
    }

    commandsData() {
        const url = process.env.NODE_ENV === "development" ? "http://localhost:3000/api/estadisticas" : "https://ha.davi.cl/api/estadisticas"

        fetch(url + "?tipo=comandos",
            {
                method: "GET",
            })
            .then(res => res.json())
            .then(
                (result) => {
                    let amnt = this.getAmmountAndCommandPerDayPerCategory(result)
                    let data = {}
                    Object.keys(amnt).forEach((categoria) => {
                        Object.keys(amnt[categoria]).forEach((fecha) => {
                            Object.keys(amnt[categoria][fecha]).forEach((comando) => {

                                if (!(categoria in data)) {
                                    data[categoria] = []
                                }

                                // check if the date is already in the data array
                                let index = data[categoria].findIndex((element) => element.name === fecha)
                                if (index !== -1) {
                                    Object.keys(data[categoria]).forEach((entry) => {
                                        if (data[categoria][entry].name === fecha) {
                                            data[categoria][entry][comando] = amnt[categoria][fecha][comando]["ammount"]
                                        }
                                    })
                                    return
                                }

                                let commandData = { name: fecha }
                                commandData[comando] = amnt[categoria][fecha][comando]["ammount"]
                                data[categoria].push(commandData)
                            })
                        })
                    })

                    // Check if any command is missing

                    ReactGA.event({
                        category: "estadisticas",
                        action: "load_commands_stats",
                        label: "Estadisticas de comandos cargadas."
                    })

                    this.setState({ commandsData: data })
                }
            )
    }

    commandsPerCategory(category) {
        let names = []
        this.state.commandsData[category].forEach((entry) => {
            Object.keys(entry).forEach((key) => {
                if (key !== "name" && !names.includes(key)) {
                    names.push(key)
                }
            })
        })
        return names
    }


    render() {
        if (this.state.commandsData.length === 0) {
            this.commandsData()
        }

        return (
            <div>
                <Button
                    variant="contained"
                    style={{ marginBottom: "20px", textTransform: "none" }}
                    onClick={() => this.props.inicio()}
                    className="btn"
                >Inicio</Button>
                {Object.keys(this.state.commandsData).map((categoria, index) => {
                    if ((index % 2) === 0) {
                        return (<Stack
                            direction="row"
                            spacing={2}
                            alignItems="center"
                            justifyContent="center"
                            id={index}
                        >
                            {Array.from(Array(2)).map((_, i) => {

                                let ind
                                if (i === 0) {
                                    ind = i
                                } else if (i === 1) {
                                    ind = 1
                                } else {
                                    ind = i
                                }

                                if (index + ind === Object.keys(this.state.commandsData).length) {
                                    ind = Object.keys(this.state.commandsData).length - index - 1
                                }

                                let category = Object.keys(this.state.commandsData)[index + ind]

                                if (this.state.addedCategory.includes(category)) {
                                    return null
                                }

                                this.state.addedCategory.push(Object.keys(this.state.commandsData)[index + ind])

                                return (<Stack
                                    direction="column"
                                    spacing={2}
                                    alignItems="center"
                                >
                                    <Typography variant="h4" style={{ marginLeft: 20 }}>
                                        Uso de Comandos de {Object.keys(this.state.commandsData)[index + ind]}
                                    </Typography>
                                    <LineChart
                                        width={600}
                                        height={300}
                                        data={this.state.commandsData[Object.keys(this.state.commandsData)[index + ind]]}
                                        margin={{
                                            top: 5,
                                            right: 30,
                                            left: 20,
                                            bottom: 5,
                                        }}
                                    >
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis dataKey="name" />
                                        <YAxis allowDecimals={false} />
                                        <Tooltip />
                                        <Legend />
                                        {this.commandsPerCategory(Object.keys(this.state.commandsData)[index + ind]).map((command) => {
                                            return <Line type="monotone" dataKey={command} stroke="#8884d8" />
                                        })}
                                    </LineChart>
                                </Stack>)
                            })}
                        </Stack>)
                    } else {
                        if (this.state.addedCategory.includes(categoria)) {
                            return null
                        }
                        return (<Stack
                            direction="column"
                            spacing={2}
                            alignItems="center"
                            id={index}
                        >
                            <Typography variant="h4" style={{ marginLeft: 20 }}>
                                Uso de Comandos de {categoria}
                            </Typography>
                            <LineChart
                                width={600}
                                height={300}
                                data={this.state.commandsData[categoria]}
                                margin={{
                                    top: 5,
                                    right: 30,
                                    left: 20,
                                    bottom: 5,
                                }}
                            >
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="name" />
                                <YAxis allowDecimals={false} />
                                <Tooltip />
                                <Legend />
                                {this.commandsPerCategory(categoria).map((command) => {
                                    return <Line type="monotone" dataKey={command} stroke="#8884d8" />
                                })}
                            </LineChart>
                        </Stack>)
                    }
                })}
            </div>
        );
    }
}

export default Estadisticas;
