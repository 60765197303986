import React from 'react';
import './constelaciones.css'
import './index.css'

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import SelectElementos from './components/SelectElementos';
import { toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

import ReactGA from "react-ga4";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

class Constelaciones extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modalOpen: false,
            agregarModalOpen: false,
            personaje: "",
            constelacionAgregar: {
                nombre: "",
                personaje: "",
                mensaje: "",
                imagen: "",
                elemento: "",

                personaje_error: false,
                mensaje_error: false,
                imagen_error: false,
                elemento_error: false,
            }
        }
    }

    onEdit(personaje) {
        this.setState({ modalOpen: true, personaje: personaje })
    }

    modalSave() {
        const nombre = document.getElementById("personaje-s").value
        const nombre_key = nombre.replace(" ", "_").toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")

        const url = process.env.NODE_ENV === "development" ? "http://localhost:3000" : "https://ha.davi.cl"
        fetch(url + "/api/constelaciones/" + nombre_key, {
            method: "PATCH",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                nombre: document.getElementById("nombre-s").value,
                personaje: document.getElementById("personaje-s").value,
                mensaje: document.getElementById("mensaje-s").value,
                imagen: document.getElementById("imagen-s").value,
                elemento: document.getElementById("elemento-s").textContent,
            })
        })
            .then(
                (result) => {
                    if (result.status === 200) {

                        ReactGA.event({
                            category: "constelaciones",
                            action: "edit_constelacion",
                            label: `Constelacion del personaje ${nombre} editada.`
                        })

                        toast.success("Constelación actualizada correctamente")
                        this.setState({ modalOpen: false })
                        this.props.reFetch();
                    } else {

                        ReactGA.event({
                            category: "constelaciones",
                            action: "edit_constelacion",
                            label: `Error al editar la constelacion del personaje ${nombre}.`
                        })

                        toast.error("Error al actualizar la constelación")
                    }
                })
    }

    agregarModalSave() {
        let nombre = document.getElementById("nombre-a")
        let personaje = document.getElementById("personaje-a")
        let mensaje = document.getElementById("mensaje-a")
        let imagen = document.getElementById("imagen-a")
        let elemento = document.getElementById("elemento-a")

        if (personaje.value === "" || mensaje.value === "" || imagen.value === "" || elemento.innerText === "Seleccione un elemento") {
            let new_error = { ...this.state.constelacionAgregar }

            new_error.personaje_error = personaje.value === "" ? true : false
            new_error.mensaje_error = mensaje.value === "" ? true : false
            new_error.imagen_error = imagen.value === "" ? true : false
            new_error.elemento_error = elemento.innerText === "Seleccione un elemento" ? true : false

            this.setState({ constelacionAgregar: new_error })
            return
        }

        const url = process.env.NODE_ENV === "development" ? "http://localhost:3000" : "https://ha.davi.cl"
        fetch(url + "/api/constelaciones", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                nombre: nombre.value,
                personaje: personaje.value,
                mensaje: mensaje.value,
                imagen: imagen.value,
                elemento: elemento.textContent,
            })
        })
            .then(
                (result) => {
                    if (result.status === 200) {

                        ReactGA.event({
                            category: "constelaciones",
                            action: "add_constelacion",
                            label: `Constelacion del personaje ${nombre.value} agregada.`
                        })

                        toast.success("Constelación creada correctamente")
                        this.setState({ agregarModalOpen: false, constelacionAgregar: { nombre: "", personaje: "", mensaje: "", imagen: "", elemento: "", personaje_error: false, mensaje_error: false, imagen_error: false, elemento_error: false } })
                        this.props.reFetch();
                    } else {

                        ReactGA.event({
                            category: "constelaciones",
                            action: "add_constelacion",
                            label: `Error al agregar la constelacion del personaje ${nombre.value}.`
                        })

                        toast.error("Error al crear la constelación")
                    }
                })
    }

    deleteConstelacion(personaje) {
        let personaje_key = personaje.replace(" ", "_").toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")
        const url = process.env.NODE_ENV === "development" ? "http://localhost:3000" : "https://ha.davi.cl"
        fetch(url + "/api/constelaciones/" + personaje_key, {
            method: "DELETE",
        })
            .then(
                (result) => {
                    if (result.status === 200) {

                        ReactGA.event({
                            category: "constelaciones",
                            action: "delete_constelacion",
                            label: `Constelacion del personaje ${personaje} eliminada.`
                        })

                        toast.success("Constelación eliminada correctamente")
                        this.setState({ modalOpen: false })
                        this.props.reFetch();
                    } else {

                        ReactGA.event({
                            category: "constelaciones",
                            action: "delete_constelacion",
                            label: `Error al eliminar la constelacion del personaje ${personaje}.`
                        })

                        toast.error("Error al eliminar la constelación")
                    }
                })
    }

    isModalOpen(personaje) {
        return this.state.modalOpen && this.state.personaje === personaje
    }

    render() {
        return (
            <div className='container constelaciones'>
                <Modal
                    open={this.state.agregarModalOpen}
                    onClose={() => this.setState({ agregarModalOpen: false })}
                >
                    <Box sx={style}>
                        <TextField
                            label="Nombre"
                            variant="outlined"
                            style={{ marginBottom: "10px", marginLeft: "10px" }}
                            id="nombre-a"
                            helperText="El nombre de la constelación"
                        />
                        <TextField
                            label="Personaje"
                            variant="outlined"
                            style={{ marginBottom: "10px", marginLeft: "10px" }}
                            id="personaje-a"
                            helperText="El nombre del personaje"
                            required
                            error={this.state.constelacionAgregar.personaje_error}
                        />
                        <div style={{ display: "flex", justifyContent: "center" }}>
                            <TextField
                                label="Mensaje"
                                variant="outlined"
                                style={{ marginBottom: "10px", marginLeft: "10px", width: "100%" }}
                                id="mensaje-a"
                                required
                                multiline
                                helperText="El mensaje de la constelación"
                                error={this.state.constelacionAgregar.mensaje_error}
                            />
                        </div>
                        <TextField
                            label="Imagen"
                            variant="outlined"
                            style={{ marginBottom: "10px", marginLeft: "10px" }}
                            id="imagen-a"
                            required
                            helperText="La imagen de la constelación"
                            error={this.state.constelacionAgregar.imagen_error}
                        />
                        <SelectElementos
                            label="Elemento *"
                            style={{ marginBottom: "10px", marginLeft: "10px" }}
                            id="elemento-a"
                            defaultValue="Elegir"
                            helperText="El elemento del personaje"
                            error={this.state.constelacionAgregar.elemento_error}
                        />
                        <Stack spacing={2} direction="row" justifyContent="center">
                            <Button
                                variant="contained"
                                onClick={() => this.setState({ agregarModalOpen: false })}
                                color="error"
                                className="btn">
                                Cancelar
                            </Button>
                            <Button
                                variant="contained"
                                onClick={() => this.agregarModalSave()}
                                className="btn">
                                Agregar
                            </Button>
                        </Stack>
                    </Box>
                </Modal>
                <Stack spacing={2} direction="row">
                    <Button
                        variant="contained"
                        style={{ marginBottom: "20px", textTransform: "none" }}
                        onClick={() => this.props.inicio()}
                        className="btn"
                    >Inicio</Button>
                    <Button
                        variant="contained"
                        style={{ marginBottom: "20px", textTransform: "none" }}
                        onClick={() => this.setState({ agregarModalOpen: true })}
                        className="btn"
                    >Agregar constelación</Button>
                </Stack>
                <table>
                    <thead>
                        <tr>
                            <th>Nombre</th>
                            <th>Personaje</th>
                            <th>Mensaje</th>
                            <th>Imagen</th>
                            <th>Elemento</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.props.constelaciones.map((constelacion) => (
                            <tr key={constelacion.nombre} className="constelacion-item">
                                <td onClick={() => this.onEdit(constelacion.personaje)} id={constelacion.id}>{constelacion.nombre}</td>
                                <td onClick={() => this.onEdit(constelacion.personaje)} id={constelacion.id}>{constelacion.personaje}</td>
                                <td onClick={() => this.onEdit(constelacion.personaje)} id={constelacion.id}>{constelacion.mensaje}</td>
                                <td onClick={() => this.onEdit(constelacion.personaje)} id={constelacion.id}>{constelacion.imagen}</td>
                                <td onClick={() => this.onEdit(constelacion.personaje)} id={constelacion.id}>{constelacion.elemento}</td>

                                <Modal open={this.state.modalOpen && this.state.personaje === constelacion.personaje} onClose={() => this.setState({ modalOpen: false })}>
                                    <Box sx={style}>
                                        <TextField
                                            label="Nombre"
                                            defaultValue={constelacion.nombre}
                                            style={{ marginBottom: "10px", marginLeft: "10px" }}
                                            id={"nombre" + (this.isModalOpen(constelacion.personaje) ? "-s" : "")}
                                        />
                                        <TextField
                                            label="Personaje"
                                            defaultValue={constelacion.personaje}
                                            style={{ marginBottom: "10px", marginLeft: "10px" }}
                                            id={"personaje" + (this.isModalOpen(constelacion.personaje) ? "-s" : "")}
                                        />
                                        <div style={{ display: "flex", justifyContent: "center" }}>
                                            <TextField
                                                label="Mensaje"
                                                multiline
                                                defaultValue={constelacion.mensaje}
                                                style={{ marginBottom: "10px", marginLeft: "10px", width: "100%" }}
                                                id={"mensaje" + (this.isModalOpen(constelacion.personaje) ? "-s" : "")}
                                            />
                                        </div>
                                        <TextField
                                            label="Imagen"
                                            multiline
                                            defaultValue={constelacion.imagen}
                                            style={{ marginBottom: "10px", marginLeft: "10px" }}
                                            id={"imagen" + (this.isModalOpen(constelacion.personaje) ? "-s" : "")}
                                        />
                                        <SelectElementos
                                            label="Elemento"
                                            defaultValue={constelacion.elemento === "" ? "Ninguno" : constelacion.elemento}
                                            style={{ marginBottom: "10px", marginLeft: "10px" }}
                                            id={"elemento" + (this.isModalOpen(constelacion.personaje) ? "-s" : "")}
                                        />
                                        <Stack spacing={2} direction="row" justifyContent="center">
                                            <Button
                                                variant="contained"
                                                style={{ marginRight: "10px" }}
                                                onClick={() => this.deleteConstelacion(constelacion.personaje)}
                                                color="error"
                                                className="btn">
                                                Eliminar
                                            </Button>
                                            <Button
                                                variant="contained"
                                                style={{ marginLeft: "10px" }}
                                                onClick={() => this.modalSave()}
                                                className="btn">
                                                Guardar
                                            </Button>
                                        </Stack>
                                    </Box>
                                </Modal>

                            </tr>
                        ))}
                    </tbody>
                </table>
            </div >
        )
    }
}

export default Constelaciones;
