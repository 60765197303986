
import './App.css';
import './index.css'

import icon from './icons/hoyoacademia.png';
import React from 'react';

import Constelaciones from './constelaciones';
import Estadisticas from './estadisticas.jsx';

import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';

import ReactGA from "react-ga4";

class App extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			page: "inicio",
			isLoaded: false,
			constelaciones: []
		}
	}

	constelaciones() {
		if (this.state.isLoaded) {
			return this.state.constelaciones
		}

		const url = process.env.ENVIROMENT === "development" ? "http://localhost:3000/api/constelaciones" : "https://ha.davi.cl/api/constelaciones"

		fetch(url)
			.then(res => res.json())
			.then(
				(result) => {

					ReactGA.event({
						category: "constelaciones",
						action: "load_constelaciones",
						label: "Constelaciones cargadas correctamente."
					})

					this.setState({
						isLoaded: true,
						constelaciones: result
					});
				}
			)
		return this.state.constelaciones
	}

	renderReal() {
		if (this.state.page === "inicio") {
			return (
				<div className="App">
					<img src={icon} alt="logo" style={{ width: "200px" }} />
					<h1>HoYoAcademia Bot</h1>
					<p>Página web creada para el bot de HoYo Academia.</p>
					<Stack
						direction="row"
						spacing={2}
						justifyContent="center"
					>
						<Button
							onClick={() => this.setState({ page: "constelaciones" })}
							variant="contained"
							style={{ textTransform: "none" }}
							className="btn">
							Constelaciones
						</Button>
						<Button
							onClick={() => this.setState({ page: "estadisticas" })}
							variant="contained"
							style={{ textTransform: "none" }}
							className="btn">
							Estadisticas
						</Button>
					</Stack>
				</div>
			);
		} else if (this.state.page === "constelaciones") {
			return (
				<div className="App">
					<h1>Constelaciones</h1>
					<Constelaciones
						constelaciones={this.constelaciones()}
						reFetch={() => this.setState({ isLoaded: false })}
						inicio={() => this.setState({ page: "inicio" })} />
				</div>
			)
		} else if (this.state.page === "estadisticas") {
			return (
				<div className="App-wo-center">
					<h1>Estadisticas</h1>
					<p>Estadisticas de uso del bot.</p>
					<p>Esto aún es trabajo en progreso, puede que no funcione correctamente c:</p>
					<Estadisticas
						inicio={() => this.setState({ page: "inicio" })} />
				</div>
			)
		}
	}

	render() {
		return this.renderReal()
	}
}

export default App;
