import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';

function SelectElementos(props) {
    return (
        <TextField
            select
            label={props.label}
            style={props.style}
            onChange={props.onChange}
            id={props.id}
            defaultValue={props.defaultValue}
            helperText={props.helperText}
            error={props.error}
        >
            <MenuItem disabled value={"Elegir"}>Seleccione un elemento</MenuItem>
            <MenuItem value={"Ninguno"}>Ninguno</MenuItem>
            <MenuItem value={"Anemo"}>Anemo</MenuItem>
            <MenuItem value={"Geo"}>Geo</MenuItem>
            <MenuItem value={"Electro"}>Electro</MenuItem>
            <MenuItem value={"Dendro"}>Dendro</MenuItem>
            <MenuItem value={"Hydro"}>Hydro</MenuItem>
            <MenuItem value={"Pyro"}>Pyro</MenuItem>
            <MenuItem value={"Cryo"}>Cryo</MenuItem>
        </TextField>
    )
}

export default SelectElementos;
